import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Link
} from "@mui/material";
import React, { useContext } from "react";
import { s3PrimaryLogo } from "../common/url";
import { WebContext } from "../contextProvider/webContextProvider";

export const TopChoiceCards = (iItem: any) => {
  const { webContent, setWebContent } = useContext(WebContext);
  const { headContent, mPosition, bodyContent, mBrandID, clickURL, Commission, requestData } = iItem
  const handleVisitSiteClick = () => {

    const conversionValue = Commission
    const transactionID = `${requestData}-${mPosition}`

    window.dataLayer.push({
      event: 'mm-cod-gtag',
      conversionValue: conversionValue,
      transactionID: transactionID
    });
  }
  return (
    <React.Fragment>
      <Card className="listing-card top-picks-card">
        <Box className="info-1">
          <Box display="flex" alignItems="center" gap="24px">
            <Box className="count"># {mPosition}</Box>
            <CardMedia
              component="img"
              image={`${s3PrimaryLogo}${mBrandID}.webp`}
              alt=""
            />
          </Box>
          <Button component={Link}
            href={clickURL}
            target="_blank"
            variant="contained"
            size="large"
            sx={{
              textTransform: "none",
              fontSize: "18px",
              borderRadius: "8px",
              minWidth: "150px",
              backgroundColor: webContent?.defaultData?.primaryColor,
              "&:hover": {
                backgroundColor: "#bd7302"
              }
            }}
            onClick={handleVisitSiteClick}
          >
            Visit Site
          </Button>
        </Box>
        <CardContent className="info-2">
          <Typography
            variant="subtitle1"
            color="text.secondary"
            component="p"
            lineHeight={1.3}
            fontWeight="600"
            className="subtitle"
          >{headContent}</Typography>
          <div dangerouslySetInnerHTML={{ __html: bodyContent }} />
        </CardContent>
      </Card>
    </React.Fragment>
  );
}
