export const TOTAL_CLICK_COUNT = '/v1/compare/count'

export const BLOG_DETAILS = '/v1/blog/details'

export const BODY_CONTENT = '/v1/content/sections'

export const s3PrimaryLogo = 'https://edu.media-matchers.com/brand/primary-logo/'

export const s3SecondaryLogo = 'https://edu.media-matchers.com/brand/secondary-logo/'

export const VOCATIONAL_TRAINING_STUDY  = '/v1/content/headers/7'

export const s3BannerImage = 'https://edu.media-matchers.com/banner'

export const SEO_API = 'https://f0720w0one.execute-api.us-east-1.amazonaws.com/staging/v3/seo/handlerById'

export const WEB_CONTENT = 'https://d3lmxxnronq3mg.cloudfront.net/staging/v3/getFrontendCmsWebContent'