export const degreeInterestOptions: any = [
    {
        "type": "Career Training",
        "value": "1"
    },
    {
        "type": "Associates Degrees",
        "value": "2"
    },
    {
        "type": "Bachelor's Degrees",
        "value": "3"
    },
    {
        "type": "Master's Degrees",
        "value": "4"
    }
]


export const mPrograms: any =
    [{ "text": "General Trade Vocational Career", "value": "700" },
    { "text": "Automotive Focused", "value": "701" },
    { "text": "Aviation Focused", "value": "702" },
    { "text": "Certified Nursing Assistant (CNA)", "value": "703" },
    { "text": "Construction Management", "value": "704" },
    { "text": "Electrical Technican", "value": "705" },
    { "text": "Fire Science", "value": "706" },
    { "text": "Truck Driver (CDL)", "value": "707" },
    { "text": "HVAC", "value": "708" },
    { "text": "Welding", "value": "709" },
    ]

