import FormIndex from "../components/LeadFormSteps/FormIndex";
import VocationTradeListing from "../Container";
import PageNotFound from "../Container/pagenotfound";

export const routePath = [
    {
        name: "Home",
        path: "/",
        exact: true,
        component: VocationTradeListing
    },
    {
        name: "General Trade Vocational Career",
        path: "/vocational&trade/generaltradevocationalcareer",
        exact: true,
        component: VocationTradeListing
    },
    {
        name: "Automotive focused",
        path: "/vocational&trade/automotivefocused",
        exact: true,
        component: VocationTradeListing
    },
    {
        name: "Aviation focused",
        path: "/vocational&trade/aviationfocused",
        exact: true,
        component: VocationTradeListing
    },
    {
        name: "Certified Nursing Assistant (CNA)",
        path: "/vocational&trade/certifiednursingassistant(cna)",
        exact: true,
        component: VocationTradeListing
    },
    {
        name: "Construction Management",
        path: "/vocational&trade/constructionmanagement",
        exact: true,
        component: VocationTradeListing
    },
    {
        name: "Electrical Technican",
        path: "/vocational&trade/electricaltechnican",
        exact: true,
        component: VocationTradeListing
    },
    {
        name: "Fire Science",
        path: "/vocational&trade/firescience",
        exact: true,
        component: VocationTradeListing
    },
    {
        name: "Truck Driver (CDL)",
        path: "/vocational&trade/truckdriver(cdl)",
        exact: true,
        component: VocationTradeListing
    },
    {
        name: "HVAC",
        path: "/vocational&trade/hvac",
        exact: true,
        component: VocationTradeListing
    },
    {
        name: "Welding",
        path: "/vocational&trade/welding",
        exact: true,
        component: VocationTradeListing
    },
    {
        name: "LeadForm",
        path: "/get-information",
        exact: true,
        component: FormIndex
    },
    {
        name: "Page Not Found",
        path: "*",
        exact: true,
        component: VocationTradeListing
    }
]
